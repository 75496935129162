import React from "react";
import "./App.css";
import { SocialIcon } from "react-social-icons";

function App() {
  return (
    <div className="App">
      <header>
        <SocialIcon
          className="social-icon"
          url="https://twitter.com/3_rigby"
          fgColor="white"
          bgColor="none"
        />
      </header>
      <div className="container">
        <div className="typewriter">
          <h1 className="logo">3_rigby</h1>
        </div>
      </div>
    </div>
  );
}

export default App;
